<template>
	<v-layout row justify-center v-if="showCampaignFaq">
		<v-dialog v-model="dialog" scrollable max-width="900px">
			<template v-slot:activator="{ on }">
				<a v-on="on" id="showFaq" @click="$gtag('send', 'event', 'header', 'home', 'FAQ')">
					<Icon :name="'uil uil-comment-question'" :size="24" />
				</a>
			</template>
			<v-card>
				<v-card-title class="headline">
					<h1>
						<!-- <small class="f-size-half fw-400 text-primary color-theme-primaria">{{ campaign.name }}</small> -->
						<div>Perguntas Frequentes</div>
					</h1>
					<a href="#"></a>
					<v-btn class="close-modal" @click="dialog = false">
						<i class="fal fa-times"></i>
					</v-btn>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<div class="faq">
						<div class="py-2 px-3">
							<InputSearch type="text" place-holder="Buscar..." v-model="search" />
						</div>
						<hr class="sm mt-0 mb-2">
						<h3
							class="text-primary color-theme-primaria"
							v-if="campaignFaqList.length != 0"
						>{{ campaign.name }}</h3>
						<template v-if="showCampaignFaq">
							<ul v-if="filteredCampaignFaqList.length != 0">
								<li v-for="campaignFaq in filteredCampaignFaqList" :key="campaignFaq.id">
									<input type="checkbox" :checked="accordion" :id="'p'+campaignFaq.id" />
									<label :for="'p'+campaignFaq.id" v-html="campaignFaq.question"></label>
									<div class="faq-answer" v-html="campaignFaq.answer"></div>
								</li>
							</ul>
							<ul v-else>
								<li>Nenhuma FAQ encontrada para esta campanha.</li>
							</ul>
						</template>
						<template v-if="false">
							<h3 class="text-primary color-theme-primaria">Abrace uma Causa</h3>
							<ul v-if="filteredGeneralFaqList.length != 0">
								<li v-for="generalFaq in filteredGeneralFaqList" :key="generalFaq.id">
									<input type="checkbox" :checked="accordion" :id="'p'+generalFaq.id" />
									<label :for="'p'+generalFaq.id" v-html="generalFaq.question"></label>
									<div class="faq-answer" v-html="generalFaq.answer"></div>
								</li>
							</ul>
							<ul v-else>
								<li>Nenhuma FAQ encontrada para esta pesquisa.</li>
							</ul>
						</template>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script type="plain/text">
import Campaign from "@/scripts/models/campaign.model";
import CampaignService from "@/scripts/services/campaign.service";

export default {
	data() {
		return {
			search: "",
			accordion: false,
			campaign: new Campaign(),
			campaignService: new CampaignService(),
			campaignFaqList: [],
			generalFaqList: [],
			dialog: false,
			showCampaignFaq: false
		};
	},
	created() {
		this.findBySubdomain();
	},
	computed: {
		filteredCampaignFaqList() {
			let filteredList = this.campaignFaqList.filter(campaignFaq => {
				return (
					campaignFaq.answer
						.toLowerCase()
						.includes(this.search.toLowerCase()) ||
					campaignFaq.question
						.toLowerCase()
						.includes(this.search.toLowerCase())
				);
			});

			let returnList = filteredList.map(i => {
				let emphCampaignFaq = {};
				let re = new RegExp("(" + this.search + ")", "gi");

				emphCampaignFaq["id"] = i.id;
				if (this.search == "") {
					emphCampaignFaq["question"] = i.question;
					emphCampaignFaq["answer"] = i.answer;
				} else {
					emphCampaignFaq["question"] = i.question.replace(
						re,
						`<b class="faq-search-text">$1</b>`
					);
					emphCampaignFaq["answer"] = i.answer.replace(
						re,
						`<b class="faq-search-text">$1</b>`
					);
				}

				this.accordion = this.search != "";
				return emphCampaignFaq;
			});

			return returnList;
		},
		filteredGeneralFaqList() {
			let filteredList = this.generalFaqList.filter(generalFaq => {
				return (
					generalFaq.answer
						.toLowerCase()
						.includes(this.search.toLowerCase()) ||
					generalFaq.question
						.toLowerCase()
						.includes(this.search.toLowerCase())
				);
			});

			let returnList = filteredList.map(i => {
				let emphGeneralFaq = {};
				let re = new RegExp("(" + this.search + ")", "gi");

				emphGeneralFaq["id"] = i.id;
				if (this.search == "") {
					emphGeneralFaq["question"] = i.question;
					emphGeneralFaq["answer"] = i.answer;
				} else {
					emphGeneralFaq["question"] = i.question.replace(
						re,
						`<b class="faq-search-text">$1</b>`
					);
					emphGeneralFaq["answer"] = i.answer.replace(
						re,
						`<b class="faq-search-text">$1</b>`
					);
				}
				return emphGeneralFaq;
			});

			this.accordion = this.search != "";
			return returnList;
		}
	},
	methods: {
		listFaqByCampaignId() {
			this.campaignService.listFaqByCampaignId(
				this.listFaqByCampaignIdCallback,
				this.campaign.id
			);
		},
		listFaqByCampaignIdCallback(data) {
			this.campaignFaqList = data;
			this.showCampaignFaq = data.length != 0;
		},
		listGeneralFaq() {
			this.campaignService.listGeneralFaq(
				this.listGeneralFaqCallback,
				this.campaign.id
			);
		},
		listGeneralFaqCallback(data) {
			this.generalFaqList = data;
		},
		findBySubdomain() {
			this.campaignService.findBySubdomain(this.findBySubdomainCallback);
		},
		findBySubdomainCallback(data) {
			if (data != undefined) {
				this.campaign = data;
				this.listFaqByCampaignId();
			}

			this.listGeneralFaq();
		}
	}
};
</script>