import File from "@/scripts/models/file.model";
import Company from "@/scripts/models/company.model";
import CampaignPaymentMethodProvider from "@/scripts/models/campaignPaymentMethodProvider.model";

export default class Campaign {

    constructor() {

        this.id = 0;
        this.name = "";
        this.campaignTypeId = 0;
        this.minimumDonationValue = 0;
        this.objetiveDonationValue = 0;
        this.isPublic = true;
        this.paymentMethodList = [];
        this.donationRecurrencePeriodList = {};
        this.projectList = [];
        this.initialDate = null;
        this.finalDate = null;
        this.subdomain = "";
        this.totalValue = 0;
        this.capturedValue = 0;
        this.file = new File();
        this.faviconFile = new File();
        this.company = new Company();
        this.published = false;
        this.campaignPhaseId = 0;
        this.campaignNewsList = [];
        this.directBeneficiaries = 0;
        this.indirectBeneficiaries = 0;
        this.hasEmployees = false;
        this.taxOwner = 1;
        this.campaignCompanyPaymentMethodList = [];
        this.ticketConfigId = null;
        this.googleAnalyticsId = "";
        this.googleTagManager = "";
        this.facebookPixelId = "";
        this.metaTitle = "";
        this.metaDescription = "";
        this.chatbotType = 1;
        this.chatbotId = 0;
        this.capturedDonationValue = 0;
        this.cpfIsRequired = true;
        this.withRecaptcha = false;
        this.loginAttempts = 0;
        this.allowAnnonymousDonation = false;
        this.informCompany = false;
        this.informDepartament = false;
        this.informAddress = true;
        this.informState = true;
        this.consentTermDefault = true;
        this.modalConfirmationOnDonationActive = false;
        this.campaignPaymentMethodProvider = new CampaignPaymentMethodProvider();
        this.campaignConsentTerms = [];
        this.campaignInputInformation = {};
        this.allowUsdDonation = true;
        this.receiver = 1;
        this.allowUsdDonation = true;       
        this.receiverParcelament = null;
        this.creditCardParcelament = 0;
        this.minimumParcelamentValue = 0;
        this.permitCnpjDonation = false;
        this.salesForceIntegrationId = null;
    }
}