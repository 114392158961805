<style scoped>
  pre {
     overflow-x: auto;
     white-space: pre-wrap;
     white-space: -moz-pre-wrap;
     white-space: -pre-wrap;
     white-space: -o-pre-wrap;
     word-wrap: break-word;
  }
</style>
<template>
	<Modal
		v-model="dialog"
		modalTitle="Termos de Uso"
		:cardTitle="true"
		:withClose="true"
		myMaxWidth="640px"
		@input="$emit('close')"
	>
		<v-container fluid pa-0>
			<v-layout pa-0>
				<v-flex list-scroll sm12 style="height:82vh">
					<div class="content-text-block overflow font-sys">
						<pre>
							<p><strong>T</strong><strong>TERMO DE USO</strong></p>
<p>O uso do site https://abraceumacausa.com.br, doravante referido como Abrace Uma Causa (AUC) &eacute; inteiramente baseado no conte&uacute;do presente nestes termos. O usu&aacute;rio, ao utilizar da plataforma, concorda com todos os pontos aqui tratados.</p>
<p><strong>Aceita&ccedil;&atilde;o dos termos</strong></p>
<p>Leia atentamente os termos aqui presentes antes de utilizar os servi&ccedil;os oferecidos pela Abrace Uma Causa cadastrada com nome jur&iacute;dico de Abrace Uma Causa Gest&atilde;o e Servi&ccedil;os LTDA. Inscrita no CNPJ sob n&deg; 26.246.070/0001-18.</p>
<p><strong>Defini&ccedil;&otilde;es iniciais</strong></p>
<p>Para efeitos de did&aacute;tica, &eacute; importante que dividamos pap&eacute;is dentro do site:</p>
<ul>
<li><strong>Abrace Uma Causa</strong>: Consultoria especializada em conectar pessoas com interesse em realizar algum trabalho volunt&aacute;rio e se aproximar de causas sociais, com organiza&ccedil;&otilde;es, movimentos e coletivos sociais que precisam de volunt&aacute;rios para atividades ou projetos. Propriet&aacute;ria da plataforma digital, respons&aacute;vel por seu funcionamento e implanta&ccedil;&atilde;o, bem como pela indica&ccedil;&atilde;o e compartilhamento de atividades ou projetos via web. &Eacute; o operador dos dados dos usu&aacute;rios das a&ccedil;&otilde;es de voluntariado.</li>
<li><strong>Gestora da A&ccedil;&atilde;o</strong>: Pessoa jur&iacute;dica que, atrav&eacute;s de termo de compromisso celebrado junto &agrave; AUC, autoriza a divulga&ccedil;&atilde;o das a&ccedil;&otilde;es de voluntariado e &eacute; controlador das informa&ccedil;&otilde;es dos usu&aacute;rios das a&ccedil;&otilde;es de voluntariado.</li>
<li><strong>Usu&aacute;rio/Volunt&aacute;rio</strong>: Pessoa f&iacute;sica que, ao tomar ci&ecirc;ncia das a&ccedil;&otilde;es de voluntariado apoiadas/indicadas pela Gestora da A&ccedil;&atilde;o, tem a oportunidade de candidatar-se a participar das a&ccedil;&otilde;es de voluntariado. &Eacute; o titular dos seus dados armazenados na plataforma.</li>
<li><strong>Institui&ccedil;&atilde;o / Fundo / Entidade Apoiada</strong>: Organiza&ccedil;&atilde;o sem fins lucrativos &agrave; qual as atividades da a&ccedil;&atilde;o de voluntariado ser&atilde;o destinadas.</li>
</ul>
<ol>
<li><strong> Quais informa&ccedil;&otilde;es est&atilde;o presentes neste Termo de Uso?</strong></li>
</ol>
<p>Neste Termo de Uso, o usu&aacute;rio do site https://abraceumacausa.com.br, doravante referido como <strong>Abrace Uma Causa (AUC)</strong> encontrar&aacute; informa&ccedil;&otilde;es sobre: o funcionamento da Abrace Uma Causa e as regras aplic&aacute;veis a ela; o arcabou&ccedil;o legal relacionado &agrave; <strong>Abrace Uma Causa</strong>; as responsabilidades do usu&aacute;rio ao utilizar a plataforma; as responsabilidades da <strong>Abrace Uma Causa</strong> Gest&atilde;o e Servi&ccedil;os LTDA. Inscrita no CNPJ sob n&deg; 26.246.070/0001-18 ao prover o site; informa&ccedil;&otilde;es para contato, caso exista alguma d&uacute;vida ou seja necess&aacute;rio atualizar informa&ccedil;&otilde;es; e o foro respons&aacute;vel por eventuais reclama&ccedil;&otilde;es caso quest&otilde;es deste Termo de Uso tenham sido violadas.</p>
<p>Al&eacute;m disso, em nossa Pol&iacute;tica de Privacidade, o usu&aacute;rio da <strong>Abrace Uma Causa</strong> encontrar&aacute; informa&ccedil;&otilde;es sobre: qual o tratamento dos dados pessoais realizados, de forma automatizada ou n&atilde;o, e a sua finalidade; os dados pessoais dos usu&aacute;rios necess&aacute;rios para a presta&ccedil;&atilde;o do servi&ccedil;o; a forma como eles s&atilde;o coletados; se h&aacute; o compartilhamento de dados com terceiros; e quais as medidas de seguran&ccedil;a implementadas para proteger os dados.</p>
<ol start="2">
<li><strong> Aceita&ccedil;&atilde;o do Termo de Uso e Pol&iacute;tica de Privacidade</strong></li>
</ol>
<p>Ao utilizar a <strong>Abrace Uma Causa</strong>, o usu&aacute;rio confirma que leu e compreendeu os Termos e Pol&iacute;ticas aplic&aacute;veis &agrave; <strong>Abrace Uma Causa</strong> e concorda em ficar vinculado a eles.</p>
<ol start="3">
<li><strong> Defini&ccedil;&otilde;es</strong></li>
</ol>
<p>Para melhor compreens&atilde;o deste documento, neste Termo de Uso e Pol&iacute;tica de Privacidade, consideram-se:</p>
<ul>
<li><strong>Abrace Uma Causa:</strong> Consultoria especializada em capta&ccedil;&atilde;o de recursos para entidades do terceiro setor. Propriet&aacute;ria da plataforma digital, respons&aacute;vel por seu funcionamento e implanta&ccedil;&atilde;o, bem como pelo gerenciamento dos recursos captados via web. &Eacute; o operador dos dados dos usu&aacute;rios das campanhas.</li>
<li><strong>Agentes de tratamento</strong>: o controlador e o operador.</li>
<li><strong>Autoridade nacional</strong>: &oacute;rg&atilde;o da administra&ccedil;&atilde;o p&uacute;blica respons&aacute;vel por zelar, implementar e fiscalizar o cumprimento desta Lei em todo o territ&oacute;rio nacional.</li>
<li><strong>C&oacute;digos maliciosos</strong>: &eacute; qualquer programa de computador, ou parte de um programa, constru&iacute;do com a inten&ccedil;&atilde;o de provocar danos, obter informa&ccedil;&otilde;es n&atilde;o autorizadas ou interromper o funcionamento de sistemas e/ou redes de computadores;</li>
<li><strong>Controlador:</strong> pessoa natural ou jur&iacute;dica, de direito p&uacute;blico ou privado, a quem competem as decis&otilde;es referentes ao tratamento de dados pessoais.</li>
<li><strong>Dado pessoal</strong>: informa&ccedil;&atilde;o relacionada a pessoa natural identificada ou identific&aacute;vel.</li>
<li><strong>Encarregado</strong>: pessoa indicada pelo controlador e operador para atuar como canal de comunica&ccedil;&atilde;o entre o controlador, os titulares dos dados e a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados (ANPD).</li>
<li><strong>Gestora da Campanha</strong>: Pessoa jur&iacute;dica que, atrav&eacute;s de termo de compromisso celebrado junto &agrave; AUC, autoriza a divulga&ccedil;&atilde;o das campanhas de doa&ccedil;&otilde;es e &eacute; controlador das informa&ccedil;&otilde;es dos usu&aacute;rios da campanha.</li>
<li><strong>Institui&ccedil;&atilde;o / Fundo / Entidade Apoiada</strong>: Organiza&ccedil;&atilde;o sem fins lucrativos &agrave; qual os recursos captados ser&atilde;o destinados. Possui projeto aprovado e vigente, nos termos das Leis de Incentivos Fiscais ou Causas escolhidas para apoio pela Gestora da Campanha.</li>
<li><strong>Internet:</strong> o sistema constitu&iacute;do do conjunto de protocolos l&oacute;gicos, estruturado em escala mundial para uso p&uacute;blico e irrestrito, com a finalidade de possibilitar a comunica&ccedil;&atilde;o de dados entre terminais por meio de diferentes redes;</li>
<li><strong>S&iacute;tios e aplicativos</strong>: s&iacute;tios e aplicativos por meio dos quais o usu&aacute;rio acessa os servi&ccedil;os e conte&uacute;dos disponibilizados;</li>
<li><strong>Terceiro:</strong> Pessoa ou entidade que n&atilde;o participa diretamente em um contrato, em um ato jur&iacute;dico ou em um neg&oacute;cio, ou que, para al&eacute;m das partes envolvidas, pode ter interesse num processo jur&iacute;dico.</li>
<li><strong>Titular:</strong> pessoa natural a quem se referem os dados pessoais que s&atilde;o objeto de tratamento.</li>
<li><strong>Tratamento:</strong> toda opera&ccedil;&atilde;o realizada com dados pessoais, como as que se referem a coleta, produ&ccedil;&atilde;o, recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento, elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou extra&ccedil;&atilde;o.</li>
<li>Usu&aacute;rio/Doador/Volunt&aacute;rio: Pessoa f&iacute;sica que, ao tomar ci&ecirc;ncia dos projetos apoiados/indicados pela Gestora da Campanha, tem a oportunidade de efetivar doa&ccedil;&otilde;es a projetos aprovados pelas leis de incentivo fiscal, doa&ccedil;&otilde;es livres sem incentivo fiscal e participar de atividades de voluntariado, remotas ou presenciais. &Eacute; o titular dos seus dados armazenados na plataforma.</li>
<li><strong>Uso compartilhado de dados</strong>: comunica&ccedil;&atilde;o, difus&atilde;o, transfer&ecirc;ncia internacional, interconex&atilde;o de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por &oacute;rg&atilde;os e entidades p&uacute;blicos no cumprimento de suas compet&ecirc;ncias legais, ou entre esses e entes privados, reciprocamente, com autoriza&ccedil;&atilde;o espec&iacute;fica, para uma ou mais modalidades de tratamento permitidas por esses entes p&uacute;blicos, ou entre entes privados.</li>
<li><strong>Viola&ccedil;&atilde;o de dados pessoais</strong>: &eacute; uma viola&ccedil;&atilde;o de seguran&ccedil;a que provoque, de modo acidental ou il&iacute;cito, a destrui&ccedil;&atilde;o, a perda, a altera&ccedil;&atilde;o, a divulga&ccedil;&atilde;o ou o acesso n&atilde;o autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.</li>
</ul>
<ol start="4">
<li><strong> Quais s&atilde;o as leis e normativos aplic&aacute;veis a Abrace Uma Causa?</strong></li>
</ol>
<ul>
<li>Lei n&ordm; 12.965, de 23 de abril de 2014 Marco Civil da Internet: estabelece princ&iacute;pios, garantias, direitos e deveres para o uso da Internet no Brasil.</li>
<li>Lei n&ordm; 13.709, de 14 de agosto de 2018: disp&otilde;e sobre a prote&ccedil;&atilde;o de dados pessoais.</li>
</ul>
<ol start="5">
<li><strong> O que &eacute; a Abrace Uma Causa?</strong></li>
</ol>
<p>A Abrace uma Causa &eacute; uma plataforma que facilita doa&ccedil;&otilde;es de pessoas f&iacute;sicas a projetos sociais.</p>
<p><strong>- Funcionalidade da Plataforma</strong></p>
<p>Ap&oacute;s celebrado o contrato entre a Gestora da Campanha e <strong>Abrace Uma Causa</strong>, os usu&aacute;rios poder&atilde;o acessar o site para realizar suas doa&ccedil;&otilde;es.</p>
<p><strong>Voluntariado:</strong></p>
<p>Selecionado o projeto / vaga / a&ccedil;&atilde;o que deseja participar, cadastra-se com todos os dados necess&aacute;rios ao cadastro na a&ccedil;&atilde;o e vincula&ccedil;&atilde;o do volunt&aacute;rio &agrave; a&ccedil;&atilde;o.</p>
<p>Para o monitoramento do projeto deve-se acessar a p&aacute;gina &ldquo;projetos&rdquo; e verificar seu andamento, pr&oacute;ximas atividades e calend&aacute;rio.</p>
<p><strong>Doa&ccedil;&otilde;es:</strong></p>
<p>Selecionado o projeto / causa que deseja apoiar, cadastra-se com todos os dados necess&aacute;rios &agrave; emiss&atilde;o de recibo e vincula&ccedil;&atilde;o da doa&ccedil;&atilde;o ao CPF. As doa&ccedil;&otilde;es ser&atilde;o efetivadas pelas formas escolhidas / habilitadas pela Gestora da Campanha, que podem ser: i) desconto em folha de pagamento; ii) pagamento de Boleto; iii) cart&atilde;o de cr&eacute;dito; iv) d&eacute;bito autom&aacute;tico em conta corrente; v ) pix.</p>
<p>Para o monitoramento do projeto deve-se acessar a p&aacute;gina &ldquo;projetos&rdquo; e verificar como anda o sistema interno da Institui&ccedil;&atilde;o.</p>
<p><strong>- Abatimento Fiscal</strong></p>
<p>As Leis de Incentivo fiscal sobre doa&ccedil;&otilde;es funcionam para que a pessoa (f&iacute;sica ou jur&iacute;dica) escolha direcionar parte de seu imposto de renda para projetos sociais reconhecidos pelo governo.</p>
<p>Na sua declara&ccedil;&atilde;o de imposto de renda (no modo completo), &eacute; poss&iacute;vel informar as doa&ccedil;&otilde;es a projetos incentivados realizadas durante o ano-exerc&iacute;cio e, com isso, o sistema da Receita Federal do Brasil efetua o abatimento (dedu&ccedil;&atilde;o) ou devolu&ccedil;&atilde;o (restitui&ccedil;&atilde;o) dos valores doados.</p>
<p>IMPORTANTE: a Abrace Uma Causa n&atilde;o garante o abatimento fiscal das doa&ccedil;&otilde;es realizadas, quando as inciativas financiadas permitirem tal mecanismo. &Eacute; dever do usu&aacute;rio organizar os documentos necess&aacute;rios &agrave; declara&ccedil;&atilde;o do Imposto de Renda e, quando poss&iacute;vel, dedu&ccedil;&atilde;o/restitui&ccedil;&atilde;o dos valores doados.</p>
<p><strong>- Campanhas N&atilde;o Incentivadas</strong></p>
<p>A Gestora da Campanha poder&aacute; ter campanhas de doa&ccedil;&otilde;es n&atilde;o incentivadas, ou seja, essas doa&ccedil;&otilde;es n&atilde;o d&atilde;o direito ao Abatimento Fiscal. &Eacute; de responsabilidade da Pessoa F&iacute;sica se atentar a qual tipo de campanha est&aacute; fazendo sua doa&ccedil;&atilde;o.</p>
<p><strong>- Forma de Doa&ccedil;&atilde;o</strong></p>
<p>As doa&ccedil;&otilde;es na plataforma da Abrace Uma Causa ocorrem por meio de pessoa f&iacute;sica que escolhe um projeto e define o valor de sua doa&ccedil;&atilde;o. Essa quantia dever&aacute; ser paga pela forma escolhida.</p>
<p>Declaro ci&ecirc;ncia que para as transa&ccedil;&otilde;es de CART&Atilde;O DE CR&Eacute;DITO, considerando principalmente sua finalidade social, n&atilde;o estar&aacute; pass&iacute;vel para pedido de &ldquo;CHARGEBACK&rdquo; junto a operadora / emissora do cart&atilde;o de cr&eacute;dito utilizado. Declaro ainda ser respons&aacute;vel pelo cart&atilde;o de cr&eacute;dito utilizado e estar ciente de que essa opera&ccedil;&atilde;o, pela sua natureza n&atilde;o est&aacute; pass&iacute;vel de estorno ou devolu&ccedil;&atilde;o ap&oacute;s o seu processamento, salvo em ocorr&ecirc;ncias fraudulentas, devidamente comprovadas.</p>
<p><strong>- Redirecionamento de Recursos</strong></p>
<p>Caso a Institui&ccedil;&atilde;o e/ou projeto escolhido pelo doador n&atilde;o possa receber as doa&ccedil;&otilde;es, o contribuinte concorda que sua doa&ccedil;&atilde;o seja destinada um projeto similar.</p>
<p>- Propriedade da Abrace Uma Causa e seus Conte&uacute;dos</p>
<p>O logotipo e qualquer outro material utilizado nesse site pertence &agrave; Abrace Uma Causa, salvo o material produzido pelos institutos apoiados. N&atilde;o &eacute; permitido, portanto, que terceiros utilizem imagens e conte&uacute;do sem autoriza&ccedil;&atilde;o pr&eacute;via da Abrace Uma Causa.</p>
<ol start="6">
<li><strong> Quais s&atilde;o os direitos do usu&aacute;rio do servi&ccedil;o?</strong></li>
</ol>
<p>O usu&aacute;rio da Abrace Uma Causa possui os seguintes direitos, conferidos pela Lei de Prote&ccedil;&atilde;o de <strong>Dados Pessoais:</strong></p>
<ul>
<li><strong>Direito de confirma&ccedil;&atilde;o e acesso</strong> (Art. 18, I e II): &eacute; o direito do usu&aacute;rio de obter da plataforma a confirma&ccedil;&atilde;o de que os dados pessoais que lhe digam respeito s&atilde;o ou n&atilde;o objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais.</li>
<li><strong>Direito de retifica&ccedil;&atilde;o </strong>(Art. 18, III): &eacute; o direito de solicitar a corre&ccedil;&atilde;o de dados incompletos, inexatos ou desatualizados.</li>
<li><strong>Direito &agrave; limita&ccedil;&atilde;o do tratamento dos dados</strong> (Art. 18, IV): &eacute; o direito do usu&aacute;rio de limitar o tratamento de seus dados pessoais, podendo exigir a elimina&ccedil;&atilde;o de dados desnecess&aacute;rios, excessivos ou tratados em desconformidade com o disposto na Lei Geral de Prote&ccedil;&atilde;o de Dados, quando aplic&aacute;vel e respeitado o cumprimento de obriga&ccedil;&atilde;o legal e regulat&oacute;ria pela Abrace Uma Causa, bem como os segredos comercial e industrial.</li>
<li><strong>Direito de oposi&ccedil;&atilde;o</strong> (Art. 18, &sect; 2&ordm;): &eacute; o direito do usu&aacute;rio de, a qualquer momento, se opor ao tratamento de dados por motivos relacionados com a sua situa&ccedil;&atilde;o particular, com fundamento em uma das hip&oacute;teses de dispensa de consentimento ou em caso de descumprimento ao disposto na Lei Geral de Prote&ccedil;&atilde;o de Dados.</li>
<li><strong>Direito de portabilidade dos dados (</strong>Art. 18, V): &eacute; o direito do usu&aacute;rio de realizar a portabilidade dos dados a outro fornecedor de servi&ccedil;o ou produto, mediante requisi&ccedil;&atilde;o expressa, de acordo com a regulamenta&ccedil;&atilde;o da autoridade nacional, observados os segredos comercial e industrial.</li>
<li><strong>Direito de n&atilde;o ser submetido a decis&otilde;es automatizadas</strong> (Art. 20, LGPD): o titular dos dados tem direito a solicitar a revis&atilde;o de decis&otilde;es tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, inclu&iacute;das as decis&otilde;es destinadas a definir o seu perfil pessoal, profissional, de consumo e de cr&eacute;dito ou os aspectos de sua personalidade.</li>
<li><strong>Direito do respeito &agrave; intimidade</strong> (Constitui&ccedil;&atilde;o Federal, Art. 5&ordm;, X) - O tratamento das informa&ccedil;&otilde;es pessoais deve ser feito de forma transparente e com respeito &agrave; intimidade, vida privada, honra e imagem das pessoas, bem como &agrave;s liberdades e garantias individuais.</li>
</ul>
<ol start="7">
<li><strong> Quais s&atilde;o as obriga&ccedil;&otilde;es dos usu&aacute;rios que utilizam a plataforma?</strong></li>
</ol>
<p>O site da Abrace Uma Causa &eacute; uma plataforma direcionada a pessoas em plena capacidade. Menores de idade n&atilde;o gozam da possibilidade de uso.</p>
<p>Ao acessar a plataforma o indiv&iacute;duo deve manifestar seu consentimento por meio do aceito ao &ldquo;termo de anu&ecirc;ncia&rdquo; presente na confirma&ccedil;&atilde;o de doa&ccedil;&atilde;o</p>
<p>O usu&aacute;rio se responsabiliza pela precis&atilde;o e veracidade dos dados informados e reconhece que a inconsist&ecirc;ncia destes poder&aacute; implicar a impossibilidade de se utilizar a Abrace Uma Causa. Durante a utiliza&ccedil;&atilde;o da plataforma, a fim de resguardar e de proteger os direitos de terceiros, o usu&aacute;rio se compromete a fornecer somente seus dados pessoais, e n&atilde;o os de terceiros e mant&ecirc;-los permanentemente atualizados.</p>
<p>O login e senha s&oacute; poder&atilde;o ser utilizados pelo usu&aacute;rio cadastrado. Ele se compromete em manter o sigilo da senha, que &eacute; pessoal e intransfer&iacute;vel, n&atilde;o sendo poss&iacute;vel, em qualquer hip&oacute;tese, a alega&ccedil;&atilde;o de uso indevido, ap&oacute;s o ato de compartilhamento.</p>
<p>O usu&aacute;rio da plataforma &eacute; respons&aacute;vel pela atualiza&ccedil;&atilde;o das suas informa&ccedil;&otilde;es pessoais e consequ&ecirc;ncias na omiss&atilde;o ou erros nas informa&ccedil;&otilde;es pessoais cadastradas.</p>
<p>O Usu&aacute;rio &eacute; respons&aacute;vel pela repara&ccedil;&atilde;o de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de viola&ccedil;&atilde;o de quaisquer direitos de outros usu&aacute;rios, de terceiros, inclusive direitos de propriedade</p>
<p>&nbsp;intelectual, de sigilo e de personalidade), que sejam causados &agrave; Abrace Uma Causa, a qualquer outro Usu&aacute;rio, ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes Termos de Uso e Pol&iacute;tica de Privacidade ou de qualquer ato praticado a partir de seu acesso &agrave; Internet e ao s&iacute;tio www.abraceumacausa.com.br.</p>
<p>A Abrace Uma Causa n&atilde;o poder&aacute; ser responsabilizada pelos seguintes fatos:</p>
<ol>
<li>Equipamento infectado ou invadido por atacantes;</li>
<li>Equipamento avariado no momento do consumo de servi&ccedil;os;</li>
<li>Prote&ccedil;&atilde;o do computador;</li>
<li>Prote&ccedil;&atilde;o das informa&ccedil;&otilde;es baseadas nos computadores dos usu&aacute;rios;</li>
<li>Abuso de uso dos computadores dos usu&aacute;rios;</li>
<li>Monitora&ccedil;&atilde;o clandestina do computador dos usu&aacute;rios;</li>
<li>Vulnerabilidades ou instabilidades existentes nos sistemas dos usu&aacute;rios;</li>
<li>Per&iacute;metro inseguro.</li>
</ol>
<p>Em nenhuma hip&oacute;tese, a Abrace Uma Causa ser&aacute; respons&aacute;vel pela instala&ccedil;&atilde;o no equipamento do Usu&aacute;rio ou de terceiros, de c&oacute;digos maliciosos (v&iacute;rus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros que venham a ser criados), em decorr&ecirc;ncia da navega&ccedil;&atilde;o na Internet pelo Usu&aacute;rio.</p>
<ol start="8">
<li><strong> Quais s&atilde;o as responsabilidades da Abrace Uma Causa com meus dados?</strong></li>
</ol>
<p>A Abrace Uma Causa se compromete a cumprir todas as legisla&ccedil;&otilde;es inerentes ao uso correto dos dados pessoais do cidad&atilde;o de forma a preservar a privacidade dos dados utilizados na plataforma, bem como a garantir todos os direitos e garantias legais dos titulares dos dados. Ela tamb&eacute;m se obriga a promover, independentemente de requerimentos, a divulga&ccedil;&atilde;o em local de f&aacute;cil acesso, no &acirc;mbito de suas compet&ecirc;ncias, de informa&ccedil;&otilde;es de interesse coletivo ou geral por eles produzidas ou custodiadas, bem como implementar controles de seguran&ccedil;a para prote&ccedil;&atilde;o dos dados pessoais dos titulares.</p>
<p>A Abrace Uma Causa poder&aacute;, quanto &agrave;s ordens judiciais de pedido das informa&ccedil;&otilde;es, compartilhar informa&ccedil;&otilde;es necess&aacute;rias para investiga&ccedil;&otilde;es ou tomar medidas relacionadas a atividades ilegais, suspeitas de fraude ou amea&ccedil;as potenciais contra pessoas, bens ou sistemas que sustentam a plataforma ou de outra forma necess&aacute;ria para cumprir com nossas obriga&ccedil;&otilde;es legais.</p>
<p>Caso ocorra, os titulares dos dados ser&atilde;o notificados, salvo quando o processo estiver em segredo de justi&ccedil;a.</p>
<p>A Abrace Uma Causa toma todas as provid&ecirc;ncias para total prote&ccedil;&atilde;o de seus dados perante terceiros.</p>
<p>A Abrace Uma Causa compromete-se em n&atilde;o fornecer informa&ccedil;&otilde;es ou dados pessoais para terceiros, salvo os necess&aacute;rios para a emiss&atilde;o do recibo por parte das Entidades e Fundos.</p>
<p>Os dados obtidos e coletados ser&atilde;o armazenados enquanto for Usu&aacute;rio da Abrace Uma Causa. Esse per&iacute;odo de armazenamento poder&aacute; ser revisto em alinhamento a qualquer nova disposi&ccedil;&atilde;o legal sobre prazo de reten&ccedil;&atilde;o. O titular pode requisitar informa&ccedil;&otilde;es sobre seus dados pessoais a qualquer momento.</p>
<ol start="9">
<li><strong> Este Termo de Uso pode ser alterado?</strong></li>
</ol>
<p>A presente vers&atilde;o deste Termo de Uso foi atualizada pela &uacute;ltima vez em: 02 de fevereiro de 2023. A Abrace Uma Causa se reserva o direito de modificar este documento a qualquer momento, especialmente para adapt&aacute;-lo &agrave;s evolu&ccedil;&otilde;es da Abrace Uma Causa, seja pela disponibiliza&ccedil;&atilde;o de novas funcionalidades, seja pela supress&atilde;o ou modifica&ccedil;&atilde;o daquelas j&aacute; existentes.</p>
<p>Qualquer altera&ccedil;&atilde;o e/ou atualiza&ccedil;&atilde;o deste Termos de Uso passar&aacute; a vigorar a partir da data de sua publica&ccedil;&atilde;o no s&iacute;tio da Abrace Uma Causa e dever&aacute; ser integralmente observada pelos Usu&aacute;rios.</p>
<ol start="10">
<li><strong> Qual o contato pelo qual o usu&aacute;rio da Abrace Uma Causa pode tirar suas d&uacute;vidas?</strong></li>
</ol>
<p>O e-mail dpo@abraceumacausa.com.br &eacute; disponibilizado para que os titulares dos dados pessoais possam demandar as solicita&ccedil;&otilde;es previstas pelo art. 18&ordm; da LGPD e para sanar quaisquer d&uacute;vidas sobre este o servi&ccedil;o ou sobre este Termo de Uso.</p>
<ol start="11">
<li><strong> Propriedade da Abrace Uma Causa e seus conte&uacute;dos</strong></li>
</ol>
<p>O logotipo e qualquer outro material utilizado nesse site pertence &agrave; Abrace Uma Causa, salvo o material produzido pelos institutos apoiados. N&atilde;o &eacute; permitido, portanto, que terceiros utilizem imagens e conte&uacute;do sem autoriza&ccedil;&atilde;o pr&eacute;via da Abrace Uma Causa.</p>
<ol start="12">
<li><strong>1</strong><strong>2</strong><strong>. Qual o foro aplic&aacute;vel &agrave; Abrace Uma Causa?</strong></li>
</ol>
<p>Este Termo ser&aacute; regido pela legisla&ccedil;&atilde;o da Rep&uacute;blica Federativa do Brasil. Qualquer reclama&ccedil;&atilde;o ou controv&eacute;rsia com base neste Termo ser&aacute; dirimida exclusivamente pela comarca de S&atilde;o Paulo-SP.&nbsp;</p>
						</pre>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</Modal>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";

export default {
	props: ["value"],
	components: {
		Modal
	},
	data() {
		return {
			dialog: false
		};
	},
	watch: {
		value() {
			this.dialog = this.value;
		}
	},
	methods: {
		toggleDialog() {
			this.dialog = !this.dialog;
			this.$emit("input", this.dialog);
		}
	}
};
</script>
