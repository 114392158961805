<template>
  <v-menu
    class="carts"
    :close-on-content-click="false"
    attach
    :nudge-width="500"
    :nudge-bottom="70"
    :nudge-left="420"
    absolute
    transition="slide-y-transition"
    v-model="showCartMenu"
    v-if="cartItems.length > 0 || showEmptyState"
  >
    <template v-slot:activator="{ on }">
      <a
        href="javascript:;"
        v-on:click="checkCartMenu"
        @click="$gtag('send', 'event', 'Carrinho', 'Carrinho', 'Carrinho')"
      >
        <i
          class="uil uil-shopping-trolley"
          style="font-size: 24px; position: relative"
        >
          <span class="badge" :class="classAnimation">{{
            cartItems ? cartItems.length : ""
          }}</span>
        </i>
      </a>
    </template>
    <v-card>
      <v-layout align-center py-3 px-4>
        <v-flex f-size-16>
          <i class="fal fa-shopping-cart mr-2 f-size-20 text-red"></i>Meu
          carrinho
          <span class="badge-red" title="Itens no carrinho">{{
            cartItems ? cartItems.length : "0"
          }}</span>
        </v-flex>
        <i class="fal fa-times close-cart" @click="showCartMenu = false"></i>
      </v-layout>

      <v-divider></v-divider>

      <v-layout column empty-cart v-if="showEmptyState">
        <v-flex>
          <img src="../assets/images/empty_cart@2x.png" alt />
        </v-flex>
        <v-flex>
          <div>Seu carrinho está vazio.</div>
        </v-flex>
      </v-layout>

      <div class="cart-list" v-else>
        <div class="item-mycart">
          <!-- <h5 class="mx-4">{{campaign.name}}</h5> -->
          <div class="scroll-cart-list">
            <template v-for="item in cartItems">
              <v-layout
                wrap
                column
                replace-item-my-cart
                align-center
                :key="item.projectId"
              >
                <v-flex product-cart-campaign>
                  <v-flex>
                    <h6>{{ campaign.name }}</h6>
                    <h4 :title="item.project ? item.project.shortName : ''">
                      {{ item.project ? item.project.shortName : "" }}
                    </h4>
                  </v-flex>
                  <a
                    class="remove-cart"
                    title="Remover do Carrinho"
                    @click="
                      openRemoveCartItem(item);
                      $gtag(
                        'send',
                        'event',
                        'Navbar no carrinho',
                        'clicou',
                        'Remover do carinho'
                      );
                    "
                  >
                    <Icon name="fal fa-trash" size="20" colorFont="#c3c6d5" />
                  </a>
                </v-flex>
                <v-flex item-cart-campaign v-if="item.donationProduct">
                  <v-flex shrink mr-3>
                    <FileImage
                      :file="
                        item.donationProduct
                          ? item.donationProduct.file
                          : item.project &&
                            item.project.projectLandingPageConfig
                          ? item.project.projectLandingPageConfig.heroFile
                          : null
                      "
                      class="project-thumb"
                      :alt="''"
                    />
                  </v-flex>
                  <v-flex>
                    <h6>Você escolheu doar para:</h6>
                    <h4>
                      {{
                        item.donationProduct ? item.donationProduct.name : ""
                      }}
                    </h4>
                  </v-flex>
                </v-flex>
              </v-layout>
            </template>
          </div>
          <div class="btn-finish-donation-mycart">
            <button
              class="btn-red px-4 btn-donation-cart"
              @click="
                gotoCart();
                $gtag(
                  'send',
                  'event',
                  'Finalizar Doação',
                  'Carrinho Doação',
                  'Finalizar Doação'
                );
              "
              title="Finalizar doação"
            >
              Finalizar doação
            </button>
          </div>
        </div>
      </div>
    </v-card>
    <AucAlert
      ref="removeCartItemAlert"
      title="Você tem certeza que deseja excluir esse projeto do seu carrinho?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="removeToCart(selectedCartItem)"
    />
  </v-menu>
</template>

<script type="plain/text">
import { mapGetters, mapActions } from "vuex";
import AucAlert from "@/components/AucAlert.vue";
import FileImage from "@/components/FileImage.vue";
import CartService from "@/scripts/services/cart.service";
import CampaignService from "@/scripts/services/campaign.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import CartItem from "@/scripts/models/cartItem.model";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";
export default {
  components: {
    FileImage,
    AucAlert
  },
  data() {
    return {
      cartService: null,
      badge: false,
      showCartMenu: false,
      showEmptyState: false,
      classAnimation: "",
      selectedCartItem: null,
      campaign: new CampaignLandingPageConfig(),
      campaignService: new CampaignService(),
      campaignTypes: CampaignTypes,
    };
  },
  computed: {
    ...mapGetters(["cartItems", "logged", "reloadCart"]),
    isAllowToOpenCartMenu() {
      return (
        this.$router.currentRoute.name != Routes.web.CartLogged &&
        this.cartItems.length > 0
      );
    }
  },
  watch: {
    logged(newValue, oldValue) {
      if (this.reloadCart || (oldValue == false && newValue)) {
        this.saveCartitems();
      }
    },
    cartItems: function(newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        this.showBadgeAnimation();
        this.openCartMenu();
      } else {
        this.showCartMenu = false;
        if (
          newValue.length == 0 &&
          this.$router.currentRoute.name != Routes.web.CartLogged &&
          this.campaign.campaignTypeId !== this.campaignTypes.Voluntariado
        )
          this.showEmptyStateAnimation();
      }
    }
  },
  created() {
    this.cartService = new CartService(null);
    this.findBySubdomain();
    // this.$store.watch(
    //   state => state.authenticated,
    //   (newValue, oldValue) => {
    //     if (!oldValue && newValue) {
    //       debugger;
    //       this.saveCartitems();
    //     }
    //   }
    // );
    // if(this.logged) {
    //   this.cartService.getCart().then(
    //     function(data) {
    //       if (data && data.cartItems) {
    //         this.updateCart(data);
    //       }
    //     }.bind(this)
    //   );
    // }
  },
  methods: {
    ...mapActions(["removeToCart", "checkoutCart", "updateCart", "setReloadCart"]),
    checkCartMenu(event) {
      if (this.isAllowToOpenCartMenu) {
        this.showCartMenu = !this.showCartMenu;
      }
    },
    openRemoveCartItem(cartitem) {
      this.selectedCartItem = cartitem;
      this.$refs.removeCartItemAlert.open();
    },
    gotoCart() {
      if(!this.logged){
        this.$store.commit("SHOW_LOGIN", Routes.web.CartLogged);
      }else{
        this.$router.push({ name: Routes.web.CartLogged });
      }
    },
    saveCartitems() {
      if (this.reloadCart) this.setReloadCart(false);
      let items = this.cartItems.map(item => {
        if (item.donationProduct) {
          return {
            projectId: item.project.id,
            donationProductId: item.donationProduct.id
          };
        } else {
          return {
            projectId: item.project.id
          };
        }
      });

      this.cartService.mergeCartItems(items).then(
        function(data) {
          if (data && data.cartItems) {
            this.updateCart(data);
          }
        }.bind(this)
      );
    },
    findBySubdomain() {
      this.campaignService.findBySubdomain(this.findBySubdomainCallback);
    },
    findBySubdomainCallback(data) {
      if (data) {
        this.campaign = data;
        this.$emit("verifyCampaign", this.campaign);
      }
    },
    showBadgeAnimation() {
      setTimeout(() => {
        this.classAnimation = "active";
        setTimeout(() => {
          this.classAnimation = "";
        }, 300);
      }, 1600);
    },
    openCartMenu() {
      setTimeout(() => {
        this.showCartMenu = true;
        setTimeout(() => {
          this.showCartMenu = false;
        }, 4000);
      }, 2300);
    },
    showEmptyStateAnimation() {
      this.showEmptyState = true;
      this.showCartMenu = true;
      setTimeout(() => {
        this.showCartMenu = false;
        setTimeout(() => {
          this.showEmptyState = false;
        }, 300);
      }, 3000);
    }
  }
};
</script>
